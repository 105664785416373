import { useEffect, useState } from 'react';
import { isTablet } from 'react-device-detect';

export const useDeviceDetectForDashboard = () => {
    const [isMobileView, setIsMobileView] = useState(() => {
        // Check both tablet and window width on initial render
        if (typeof window !== 'undefined') {
            return window.innerWidth <= 1325;
        }
        return isTablet;
    });
  
    useEffect(() => {
        // Initial check
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 1325);
        };
        
        // Run once on mount
        handleResize();
  
        // Add event listener
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return isMobileView;
};